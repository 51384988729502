* {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}
.App {
  padding: 0px;
}

.card > a {
  text-decoration: none;
  color: black;
}
